<template>
	<!-- //会员下载的地方 -->
	<div class=" mart15  " v-if="detail.has_file==1">
		<div class="flex-column main  bg-white main w1200  ">
			<div class="justify-content-between paddlr20">
				<div class="h40 fz14 co-333333 align-items-center">附件</div>
				<div class="h40 fz14 co-010085 align-items-center cure-pointer" v-if="detail.is_vip_free==1"
					@click="godetails">会员免费下载</div>
			</div>
			<div class="justify-content-between paddlr20 mart20 marb20"
				v-if="(obj.type == 'source'&&detail.is_buy==0&&detail.is_vip==0 && detail.is_vip_free==0) || (detail.can_download == 0) || (obj.type == 'inspiration' && detail.is_buy==0 && detail.is_vip_free==0) || (detail.is_buy==0 && detail.is_vip_free==0) || ((detail.is_vip==1 || detail.is_vip==2) && detail.is_vip_free==0&&detail.is_buy==0)">
				<div class=" fz14 co-333333 align-items-center">
					<div class="flex column h100  ">
						<div class=" fz14 co-333333 align-items-center">附件已加密,请点击购买解密下载</div>
						<div class=" fz14 co-FE3000 align-items-center mart18"
							v-if="detail.pay_type==1 || obj.type == 'source'">￥{{detail.price}}</div>
						<div class=" fz14 co-FE3000 align-items-center mart18" v-if="detail.pay_type==2">
							{{Number(detail.point)}}积分
						</div>
					</div>
				</div>
				<div class=" fz14 co-010085 align-items-center">
					<div class="title-item center cure-pointer fz14 bg-DCFF03" @click="showBuyPopup"
						v-if="detail.pay_type==1 || obj.type == 'source'">
						购买
					</div>
					<div class="title-item center cure-pointer fz14 bg-DCFF03" @click="showBuyPopup"
						v-if="detail.pay_type==2">
						购买
					</div>
				</div>
			</div>
			<div class="justify-content-between paddlr20 mart20"
				v-if="(detail.is_vip_free==1 &&(detail.is_vip == 1||detail.is_vip == 2)) || (detail.is_buy == 1)">
				<div class=" justify-content-between w100" v-for="(item,index) in detail.files" :key="index">
					<div class=" fz14 co-333333 align-items-center">
						<img class="head-pic " src="@/assets/img/sub_05.png" alt="" />
						<div class="flex column h100 marl15">
							<div class=" fz14 co-333333 align-items-center">{{item.name}}</div>
							<div v-if="obj.type == 'inspiration'">
								<div class=" fz14 co-FE3000 align-items-center mart18" v-if="detail.pay_type==2">
									所需积分：{{Number(detail.price) || 0}}分</div>
								<div class=" fz14 co-FE3000 align-items-center mart18" v-if="detail.pay_type==1">
									￥{{Number(detail.price) || 0}}</div>
							</div>
						</div>
					</div>
					<div class=" fz14 co-010085 align-items-center" v-if="item.path"
						@click="dowloadUrl(item.path,item.name)">
						<div class="title-item center cure-pointer fz14 bg-DCFF03">
							下载
						</div>
					</div>
				</div>

			</div>
			<div class="justify-content-between paddlr20 mart30 marb20"
				v-if="(detail.is_vip_free==1 &&(detail.is_vip == 1 || detail.is_vip == 2)) || (detail.is_buy == 1)">
				<div class=" fz14 co-333333 align-items-center">
					<div class="flex column h100  ">
						<div class=" fz14 co-333333 align-items-center">{{detail.bdy_link || ''}}</div>
						<div class=" fz14 co-333333 align-items-center mart10">制这段内容后打开百度网盘手机App，操作更方便哦</div>
					</div>
				</div>
				<div class=" fz14 co-010085 align-items-center" @click="copyShaneUrl(detail.bdy_link)">
					<div class="title-item center cure-pointer fz14 bg-DCFF03">
						复制链接
					</div>
				</div>
			</div>

		</div>
		<el-dialog title="选择支付方式" v-model="dialogFormVisible" :center='true' :width='600'>
			<div class="display-flex">
				<div class="flex-1 flex-column align-items-center justify-content-center cure-pointer" @click="choosepay(i)" v-for="(e,i) in wayList" :key="i">
					<img class="head-pic " :src="e.icon" alt="" />
					<div class="#co-333333 fz16 mart10">
						{{e.txt}}
					</div>
				</div>
		
			</div>
		</el-dialog>
		<el-dialog title="微信支付" v-model="pay" :center='true' :width='600'>
			<div class="puops">
				<div class="qrcode-box" ref="payqrcodes"></div>
				<p>请使用手机打开微信扫描二维码完成支付</p>
			</div>
		</el-dialog>
	</div>
	
	<div v-loading.fullscreen.lock="fullscreenLoading"></div>
</template>

<script>
	import {
		getCurrentInstance,
		ref,
		reactive,
		watch
	} from 'vue';
	import QRCode from 'qrcodejs2';
	export default {
		name: 'vipdownload',
		props: {
			detail: {
				type: Object,
				default () {
					return {}
				}
			},
			obj: {
				type: Object,
				default () {
					return {}
				}
			}

		},
		setup() {
			const req1 = getCurrentInstance();
			const request = req1.appContext.config.globalProperties;
			let WxLogInData = reactive({
				data: {
					appid: "",
					redirect_uri: encodeURIComponent(),
				}
			})
			return {
				request,
				WxLogInData,

			};
		},
		data() {
			return {
				fullscreenLoading: false, //支付加载
				wayList: [{
						txt: '微信支付',
						icon: require('@/assets/img/sub_02.png'),
						value: 1,
					},
					// {
					// 	txt: '支付宝支付',
					// 	icon: require('@/assets/img/sub_01.png'),
					// 	value: 2,
					// },
				],
				way: 0, //选择支付方式
				dialogFormVisible: false,
				loading: false,
				dowloanUrl: '',
				order_id:'',
				payqrcode:"",
				pay:false
			}
		},
		mounted() {
		

		},
		watch: {

		},
		methods: {
			// 复制
			copyShaneUrl(shareLink) {

				var _input = document.createElement("input"); // 直接构建input
				_input.value = shareLink; // 设置内容
				document.body.appendChild(_input); // 添加临时实例
				_input.select(); // 选择实例内容
				document.execCommand("Copy"); // 执行复制
				document.body.removeChild(_input); // 删除临时实例
				this.$message.success("复制成功");
			},
			showBuyPopup() {
		console.log(this.obj)
				// 展示购买弹窗
				if (this.obj.type == 'source') {
					let  orderDetails = this.detail
					orderDetails = this.detail;
					orderDetails.type = 'source';
										
					this.$router.push({
						path: '/SubmitOrder',
						query: {
							type: 1,
							orderDetail:JSON.stringify(orderDetails)
						}
					})
				} else {
					if (this.detail.pay_type == 1) {
						// 钱
						this.dialogFormVisible = !this.dialogFormVisible;
					} else {
						// 积分
						this.sureBuy()

					}
				}
			},
			godetails() {
				this.$router.push({
					path: '/Myvip',

				})
			},
			choosepay(e){
				this.way = e;
				this.dialogFormVisible = !this.dialogFormVisible;
				
				this.fullscreenLoading =true;			
				this.$req({
					method: 'post',
					data: {
						id: this.detail.id,
						pay_type:this.wayList[this.way].value,
						trade_type:3		
					},
					url: '/api/article/order',
					success: res => {
						this.pay = !this.pay;
						this.fullscreenLoading = false;
					},
					fail: error => {
						if (error.data.code == 2001) {		
							let url = error.data.data.code_url; // 微信支付链接
							this.order_id = error.data.data.order_id
							if (this.wayList[this.way].value == 1) {
								this.pay = true;
								window.requestAnimationFrame(() => {									
										this.fullscreenLoading = false;
										this.$refs.payqrcodes.innerHTML = '';							
										//支付二维码
										let qrcode = new QRCode(this.$refs.payqrcodes, {
											text: url, // 需要转换为二维码的内容
											width: 150,
											height: 150,
											colorDark: '#000000',
											colorLight: '#ffffff',
											correctLevel: QRCode.CorrectLevel.H
										})
										this.getOrderStatus(); // 获取订单详情																
								})
							} else {
								this.fullscreenLoading = false;
								let html = error.data.data.param;
								const div = document.createElement('divform');
								div.innerHTML = html;
								document.body.appendChild(div);
								// document.forms[0].acceptCharset='GBK'; //保持与支付宝默认编码格式一致，如果不一致将会出现：调试错误，请回到请求来源地，重新发起请求，错误代码 invalid-signature 错误原因: 验签出错，建议检查签名字符串或签名私钥与应用公钥是否匹配
								document.forms[0].submit();
						
							}
						
						}
					}
				});
			},		
			getOrderStatus() {
			
				this.$req({
					method: 'get',
					url: '/api/mapleOrder/detail',
					data: {
						id: this.order_id
					},
					success: res => {
						let data = res;
						if (data.status > 1) {
							this.$message.success("支付成功");
							this.pay = false;
							this.speedIndex = 3;
							window.requestAnimationFrame(() => {
								this.request.tokenSuccess();
							})
							location.reload();
						} else if (data.status < 0) {
							this.pay = false;
							this.$message.warning("订单已取消");
						} else if (this.pay) {
							let a = setTimeout(() => {
								clearTimeout(a);
								this.getOrderStatus(); // 获取订单详情
							}, 2000)
						}
					},
					fail: error => {
			
					}
				});
			},
			sureBuy() {

				console.log('用积分购买')
				this.$req({
					method: 'post',
					data: {
						id: this.detail.id,
					},
					url: '/api/article/order',
					success: res => {
						this.$message.success("购买成功");
						window.requestAnimationFrame(() => {
							this.request.tokenSuccess();
						})
						location.reload();
					},
					fail: error => {
						this.$message.warning(error.data.message+'无法购买');
						console.log(error)
					}
				});
			},
			dowloadUrl(url, fileName) {
				// 打开浏览器并下载
				this.dowloanUrl = url;
				this.$message.success("下载成功");
				fetch(url).then(res => res.blob()).then(blob => {
					let eleLink = document.createElement("a");
					eleLink.style.display = "none";
					eleLink.target = "_blank";
					eleLink.setAttribute('download', fileName);
					eleLink.href = URL.createObjectURL(blob);
					document.body.appendChild(eleLink);
					eleLink.click();
					URL.revokeObjectURL(eleLink.href);
					document.body.removeChild(eleLink);
				})
			},
		}
	}
</script>

<style scope lang="less">
	.puops {
		display: flex;
		align-items: center;
		min-height: 316px;
		flex-direction: column;
	
		h2 {
			width: 100%;
			text-align: center;
			font-size: 20px;
			font-weight: 500;
			color: #131313;
			border-bottom: 1px solid #d7dae2;
			padding-bottom: 22.5px;
		}
	
		.order_pay {
			display: flex;
			flex-direction: column;
			background-color: #e5e5e5;
			width: 100%;
			padding: 21px 35px;
		}
	
		.qrcode-box {
			margin: 40px 0;
			width: 150px;
			height: 150px;
			background-color: #f9f9f9;
			overflow: hidden;
	
			canvas+img {
				width: 100%;
				height: 100%;
			}
		}
	
		p {
			font-size: 19px;
			font-weight: 500;
			color: #E84516;
			opacity: 0.5;
		}
	}


	.head-pic {
		width: 50px;
		height: 58px;
	}

	.title-item {
		min-width: 70px;
		padding: 0 5px;
		height: 28px;
		border-radius: 3px;
	}

	.main {
		margin: 0 auto;

		.de-01 {
			width: 20px;
			height: 20px;
		}

		.de-03 {
			width: 20px;
			height: 20px;
		}

		.de-04 {
			width: 32px;
			height: 32px;
		}

		.mian-pic {
			width: 1160px;
			height: 450px;
		}

		.like-btn {
			padding: 0 28px;
			height: 50px;
			border: 1px solid #999999;
			border-radius: 30px;
		}

		.like-btn-active {
			border: 1px solid #DCFF03 !important;
		}

		.de-07 {
			width: 30px;
			height: 30px;
		}

		.main-content {
			padding-left: 20px;
			padding-right: 19px;
		}
	}
</style>
