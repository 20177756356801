<template>
	<div class="mart10">
		<div class="w1200 main bg-white">
			<div class="paddlr20 mart10">
				<div class="h40 fz16 co-333333 align-items-center">评论({{info.comment || 0}})</div>
			</div>
			<div class="paddlr20">
				<div class=" paddt10 paddb22">
					<div class="bg-F7F7F7 paddb12">
						<textarea class="textarea fz12 co-333333" v-model="content" :placeholder="pla||'发表评论'"
							@blur="blurInput" :focus="focus" ref="commentInput"></textarea>
						<div class="paddlr10 justify-content-center-between">
							<img class="head-pic-01 display-block" :src="userInfo.avatar" alt="" />
							<div class="align-items-center cure-pointer">
								<div class="relative" @mousemove="showemojis = true">
									<img class="de_08 display-block" src="@/assets/img/de_08.png" alt="" />
									<div class="absolute em-box center cure-pointer" v-if="showemojis"
										@mouseleave="showemojis = false">
										<el-scrollbar height="150px">
											<div class="flex-wrap em-box-inside">
												<div class="em-item center fz20" v-for="(item, i) in emojiArr" :key="i"
													@click="chooseEm(item)">{{item}}</div>
											</div>
										</el-scrollbar>
									</div>
								</div>
								<div class="fz14 co-010085 center submit-btn marl14" @click="searchInputChage">提交评论
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'commoent',
		props: {
			Say_others: {
				type: [Boolean],
				default () {
					return false
				}
			},
			cid: {
				type: [Number, String],
				default () {
					return ''
				}
			},
			info: {
				type: Object,
				default () {
					return {}
				}
			},
			focus: {
				type: [Boolean],
				default () {
					return false
				}
			},
			pla: {
				type: [String],
				default () {
					return ''
				}
			},
			obj: {
				type: Object,
				default () {
					return {
						type: 'course'
					}
				}
			},
			dataObj: {
				type: Object,
				default () {
					return {
						type: 'course_favorite',
						type1: 'course_collect'
					}
				}
			},
		},
		data() {
			return {
				placeholder: '', // 回复默认显示文字
				emojiArr: ['😀', '😁', '😂', '😃', '😄', '😅', '😆', '😉', '😊', '😋', '😎', '😍', '😘', '😗', '😙', '😚',
					'😇', '😐', '😑', '😶', '😏', '😣', '😥', '😮', '😯', '😪', '😫', '😴', '😌', '😛', '😜', '😝',
					'😒', '😓', '😔', '😕', '😲', '😷', '😖', '😞', '😟', '😤', '😢', '😭', '😦', '😧', '😨', '😬',
					'😰', '😱', '😳', '😵', '😡', '😠', '🌹', '🍀', '🍎', '💰', '📱', '🌙', '🍁', '🍂', '🍃', '🌷',
					'💎', '🔪', '🔫', '🏀', '👄', '👍', '🔥', '💪', '👈', '👉', '👆', '👇', '👌', '👍', '👎', '✊'
				], // 表情数组
				content: '', // 输入内容
				showemojis: false, // 是否显示表情弹窗
				commentLike: false, // 评论点赞
				replyLike: false, // 回复点赞
				state: 1, // 列表加载状态
				page: 0, // 分页
				loading: false, // 接口加载状态
				dataArr: [], //数据接收列表
				isClick: false,
				isGetting: false,
				bottomInfo: '', // 底部点赞数量与状态
				isSend: false,
				cid1: '',
				userInfo: {},
				timer: true,

			}
		},
		watch: {
			cid: {
				handler() {
					this.cid1 = this.cid
				},
				immediate: true,
				deep: true,
			},
			pla: {
				handler(n, l) {

				},
				immediate: true,
				deep: true,
			},
		},
		created() {
			let userInfo = JSON.parse(localStorage.getItem('user_info'))
			this.userInfo = userInfo;

		},
		methods: {
			blurInput() {

				// 失去焦点事件
				if (this.isSend) return
				setTimeout(() => {
					this.$emit('blurInput')
				}, 500)
			},
			// 选择表情
			chooseEm(e) {
				this.content += e;
			},
			searchInputChage: function() {
				// this.fnThrottle(this.searchAction, 1000, 1000)();
			
				if (this.timer) {
					this.timer = false;
					this.sendComment()
				}else{
					setTimeout(() => {
						this.timer = true
					},5000)
				}
			
			},
			sendComment() {
				// 发送评论
				this.isSend = true;
				let userInfo = localStorage.getItem('user_info')
				this.content = this.content.replace(' ', '')
				if (!this.content) {
					this.isSend = false
					return
				}
				console.log({
					id: this.info.id,
					content: this.content,
					comment_id: this.cid1,
					...this.obj
				}, "回复参数")
				this.$req({
					method: 'post',
					url: '/api/comment',
					data: {
						id: this.info.id,
						content: this.content,
						comment_id: this.cid1,
						...this.obj
					},
					success: res => {
						this.content = ''
						this.isSend = false;
						this.cid1 = '';
						this.$message.success('发表成功')
						this.blurInput()
						res.favorite = 0
						res.is_favorite = 0
						this.$emit('sendConfirm', res)
						if (!this.cid1) {
							// 代表是给课程评论的
							res.reply = []
						} else {
							this.cid1 = '';
						}

					},
					fail: err => {
						this.isSend = false
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.em-box {
		width: 320px;
		height: 150px;
		padding: 13px;
		overflow-y: hidden;
		background: #FFFFFF;
		box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.08);
		border-radius: 8px;
		right: -96px;
		top: 38px;

		.em-box-inside {
			border-bottom: 1px solid #E6E6E6;

			.em-item {
				width: 30px;
				height: 30px;
				border-left: 1px solid #E6E6E6;
				border-top: 1px solid #E6E6E6;
				border-right: 1px solid #E6E6E6;
			}
		}
	}

	.title-item {
		min-width: 70px;
		padding: 0 5px;
		height: 28px;
		border-radius: 3px;
	}

	.main {
		margin: 0 auto;

		.de-01 {
			width: 20px;
			height: 20px;
		}

		.de-03 {
			width: 20px;
			height: 20px;
		}

		.de-04 {
			width: 32px;
			height: 32px;
		}

		.mian-pic {
			width: 1160px;
			height: 450px;
		}

		.like-btn {
			padding: 0 28px;
			height: 50px;
			border: 1px solid #999999;
			border-radius: 30px;
		}

		.like-btn-active {
			border: 1px solid #DCFF03 !important;
		}

		.de-07 {
			width: 30px;
			height: 30px;
		}

		.main-content {
			padding-left: 20px;
			padding-right: 19px;
		}
	}

	.textarea {
		width: 90%;
		padding: 10px;
		height: 54px;
		outline: none;
		background: #F7F7F7;
		border: 0;
		resize: none;
	}

	.head-pic-01 {
		width: 24px;
		height: 24px;
		border-radius: 50%;
	}

	.de_08 {
		width: 20px;
		height: 20px;
	}

	.head-pic {
		width: 50px;
		height: 58px;
	}

	.submit-btn {
		width: 72px;
		height: 28px;
		background: #DCFF03;
		border-radius: 3px;
	}

	.head-pic-02 {
		width: 30px;
		height: 30px;
		border-radius: 50%;
	}

	.comment-like {
		width: 20px;
		height: 20px;
	}
</style>
